<template>
  <v-row align="center">
    <v-col cols="12" lg="12" sm="12" align="center">
      <v-alert color="primary" dark icon="mdi-qrcode" border="left" prominent>
        <div class="font-weight-bold">
          Elige la opcion que prefieras para hacer esta solicitud!
        </div>
      </v-alert>
    </v-col>

    <v-col cols="12" md="12" sm="12" v-if="!code">
      <v-list>
        <v-list-item @click="() => sendCharge()">
          <v-list-item-avatar>
            <v-avatar color="primary">
              <v-icon color="white">mdi-cellphone</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Solicitar por numero celular</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item @click="() => generateCode()">
          <v-list-item-avatar>
            <v-avatar color="primary">
              <v-icon color="white">mdi-qrcode</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Crear codigo QR</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col cols="12" md="12" sm="12" align="center" v-if="code">
      <v-col cols="12" lg="6" sm="12">
        <vue-qr
          :text="code"
          :size="350"
          :correctLevel="3"
          :binarize="true"
          :binarizeThreshold="350"
          v-if="code != null"
        ></vue-qr>

        <v-text-field
          label="Id"
          :value="order.ocId"
          outlined
          readonly
        ></v-text-field>

        <v-text-field
          label="Nombre"
          :value="order.nombreContacto"
          outlined
          readonly
        ></v-text-field>

        <v-text-field
          label="Telefono"
          :value="order.telefono"
          outlined
          readonly
        ></v-text-field>

        <v-text-field
          label="Correo electronico"
          :value="order.email"
          outlined
          readonly
        ></v-text-field>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import VueQr from "vue-qr";
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  components: {
    VueQr,
  },
  props: {
    order: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      code: null,
      isSended: false,
    };
  },
  methods: {
    generateCode() {
      let order = this.order;

      let payment = {
        concepto: `Order #${order.ocId}`,
        monto: order.total,
        ref: 0,
        codigoInterno: order.ocId,
        tblEntEntidad: order.tblEntEntidad,
        phone: order.telefono,
      };

      axios.put(`api/v1/codi/charges`, payment).then((res) => {
        let code = _.get(res, "data.data.mc");
        this.code = code;
      });
    },
    sendCharge() {
      let order = this.order;

      let payment = {
        concepto: `Order #${order.ocId}`,
        monto: order.total,
        ref: 0,
        codigoInterno: order.ocId,
        tblEntEntidad: order.tblEntEntidad,
        phone: order.telefono,
      };

      axios
        .post(`api/v1/codi/charges`, payment)
        .then((res) => {
          this.$dialog({
            title: 'CoDi',
            text: "Se ha enviado una solicitud de cobro a su numero celular!"
          }).then(()=>{

          })
        })
        .catch((err) => {
          this.$console.log(err);
        });
    }
  }
};
</script>